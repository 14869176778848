const nameLookup = {
    "03d9e8b16bd9b22d3345d6d4cde31be1c3e1d161532e3d0ccecb95ece2eb58336e": "NeoSPCC",
    "03b209fd4f53a7170ea4444e0cb0a6bb6a53c2bd016926989cf85f9b0fba17a70c": "NF1",
    "02486fd15702c4490a26703112a5cc1d0923fd697a33406bd5a1c00e0013b09a70": "NF7",
    "02ca0e27697b9c248f6f16e085fd0061e26f44da85b58ee835c110caa5ec3ba554": "NF4",
    "02aaec38470f6aad0042c6e877cfd8087d2676b0f516fddd362801b9bd3936399e": "NF6",
    "0239a37436652f41b3b802ca44cbcb7d65d3aa0b88c9a0380243bdbe1aaa5cb35b": "The Neo Order",
    "03b8d9d5771d8f513aa0869b9cc8d50986403b78c6da36890638c3d46a5adce04a": "NF3",
    "0389ba00856f58bdc2b7c3fd6c2e73ed4829252f38083c1e295574ca599e93fe82": "lazynode",
    "02cc10d0e929ca752cfd3408bedda06463e2d93fd435e4c2b86a895b3792dee4c8": "InfStones",
    "02237309a0633ff930d51856db01d17c829a5b2e5cc2638e9c03b4cfa8e9c9f971": "NEXT (NeoLine)",
    "035d574cc6a904e82dfd82d7f6fc9c2ca042d4410a4910ecc8c07a07db49dc6513": "Everstake",
    "0248a37e04c7a5fb9fdc9f0323b2955a94cbb2296d2ad1feacea24ec774a87c4a4": "Neo News Today",
    "02946248f71bdf14933e6735da9867e81cc9eea0b5895329aa7f71e7745cf40659": "COZ",
    "031de8a766da668b2935351acd8f23c26dbedd54b8208b135f0a636b544c9e0dad": "Flamingo",
    "03fd04de983f4e04c9629ab3cfc83f41be7431b96bf852a91873c38ca8f737ee2c": "Nash.io",
    "03734d4b637dbac04d0eb45198bfe9c5a42aca907e8fd1e741eb52def583347257": "bNEO representative",
    "0392fbd1d809a3c62f7dcde8f25454a1570830a21e4b014b3f362a79baf413e115": "Switcheo Labs",
    "02e4e0db9314a42bebcb7e348a7e1ab8d4a87f518371485b0d66dbc0368f8cf58d": "chainnode",
    "034f7ea4ca4506ef288c5d5ed61686b9f39a0bc5f7670858305e32ea504ab543f3": "BinanceStaking1",
    "02ec143f00b88524caf36a0121c2de09eef0519ddbe1c710a00f0e2663201ee4c0": "AxLabs (neow3j)",
    "02beaf473e48740f8ac1b70ff2b6cdb850a7c247b9d036508d6f0bdaa1e750eb3f": "GhostMarket",
    "02784c0e81f6e3eadbd1516f9311d1fe0662f9c360ea331d9d6a4934800c96ed47": "BinanceStaking",
    "029b46bf20b19fb3da1a4591bac09acd86c460ae29cb74c7634d658702205ec2f7": "NGD4",
    "02df48f60e8f3e01c48ff40b9b7f1310d7a8b2a193188befe1c2e3df740e895093": "NF2",
    "022f1beae94cf0d266d7d26691b431958c8d13768103ab20aed817b57568da293f": "NGD2",
    "0353f7aff015d3d204eecd508f4aa67f447df4d15ec4ba649f4fa91bdb7a78a354": "NGD1",
    "024c8b6b34990a164fc7860dc497babd71911d049bdf8e186f5f51258b98509434": "NGD3",
    "024c7b7fb6c310fccf1ba33b082519d82964ea93868d676662d4a59ad548df0e7d": "NF5",
    "02ada21c39ccc5ff4a0547270bee2eb940c394c3afe0d8f5470e2fe5b56759cee2": ""
}

export const getInfo = async () => {

    const neoPrice = await fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=neo&vs_currencies=usd",
        {
            method: "GET",
            headers: {"Content-Type": "application/json"}
        }
    ).then(response => response.json()).then(data => data.neo.usd);

    const gasPrice = await fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=gas&vs_currencies=usd",
        {
            method: "GET",
            headers: {"Content-Type": "application/json"}
        }
    ).then(response => response.json()).then(data => data.gas.usd);

    const candidates = await fetch(
        "https://n3seed2.ngd.network:10332/",
        {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                "jsonrpc": "2.0",
                "method": "getcandidates",
                "params": [],
                "id": 1
            })
        }
    ).then(response => response.json()).then(data => data.result);

    candidates.sort((a, b) => parseInt(a.votes) > parseInt(b.votes) ? -1 : 1);

    const blocks = await fetch(
        "https://explorer.onegate.space/api",
        {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                "jsonrpc": "2.0",
                "id": 1,
                "params": {"Limit": 20, "Skip": 0},
                "method": "GetBlockInfoList"
            })
        }
    ).then(response => response.json()).then(data => data.result.result);

    //console.log(blocks);

    const averageBlockTime = (blocks[0].timestamp - blocks[blocks.length - 1].timestamp) / (blocks.length - 1) / 1000;

    // const averageBlockTime = blocks.reduce((sum, value) => {
    //   return sum + value.blocktime / 1000;
    // }, 0) / 15;


    const blocksPerYear = 60 * 60 * 24 * 365 / averageBlockTime;
    const baseGas = 5 * 0.1 / 100000000;

    const table_data = candidates.map((candidate, index) => {

        let totalGas = baseGas;

        if (index < 7)
            totalGas += 5 * 0.4 / 7 / candidate.votes;
        else if (index < 21)
            totalGas += 5 * 0.4 / 14 / candidate.votes

        return {
            "key": index + 1,
            "name": nameLookup[candidate.publickey],
            "address": candidate.publickey,
            "votes": candidate.votes,
            "gas_per_block": totalGas,
            "apr": (totalGas * blocksPerYear * gasPrice / neoPrice * 100).toFixed(4) + "%",
            "status": index < 7 ? "Council Member" : index < 21 ? "Consensus Node" : "Candidate"
        }
    });

    return {
        average_block_time: averageBlockTime,
        neo_price: neoPrice,
        gas_price: gasPrice,
        neo_gas_ratio: (neoPrice / gasPrice).toFixed(2),
        gas_per_year: (5 * blocksPerYear).toFixed(0),
        base_gas_per_year: (baseGas * blocksPerYear).toFixed(4),
        table_data: table_data
    }

}