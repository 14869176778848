import React, {useEffect, useState} from 'react';
import "./Home.css";
import {getInfo} from "../tools/neo";
import {InputNumber, Table} from "antd";
import {Currency} from "@depay/local-currency";

const Home = () => {

  const [info, setInfo] = useState({});
  const [neoHoldings, setNeoHoldings] = useState(1);
  const [hovering, setHovering] = useState("");
  const [currencyRate, setCurrencyRate] = useState(1.0);
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    getInfo().then(result => setInfo(result)).then(() => setLoaded(true));
    Currency.rate({from: "USD", to: Currency.getCode()}).then(result => setCurrencyRate(result));
  }, []);

  const convertCurrency = (usd) => {
    return (usd / currencyRate).toFixed(2) + " " + Currency.getCode();
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 150
    },
    {
      title: "Votes",
      dataIndex: "votes",
      key: "votes"
    },
    {
      title: "APR",
      dataIndex: "apr",
      key: "apr"
    },
    // {
    //   title: "GAS per block",
    //   dataIndex: "gas_per_block",
    //   key: "gas_per_block",
    //   render: value => <>{(value * neoHoldings).toFixed(4)}</>
    // },
    {
      title: "GAS / day",
      dataIndex: "gas_per_block",
      key: "gas_per_block",
      render: value => <>{(value * 60 * 60 * 24 * neoHoldings / info.average_block_time).toFixed(4)}</>
    },
    {
      title: "GAS / month",
      dataIndex: "gas_per_block",
      key: "gas_per_block",
      render: value => <>{(value * 60 * 60 * 24 * 30 * neoHoldings / info.average_block_time).toFixed(4)}</>
    },
    {
      title: "GAS / year",
      dataIndex: "gas_per_block",
      key: "gas_per_block",
      render: value => <>{(value * 60 * 60 * 24 * 365 * neoHoldings / info.average_block_time).toFixed(4)}</>
    }
  ]

  return (
      <div className="Home">
        <h1>NEO Voting Yield</h1>
        <div className="stats">
          <div>
            <div>
              <p>Average Block Time</p>
              <p className="small">(Based on last 15 blocks)</p>
            </div>
            <p>{loaded ? (info.average_block_time || 15).toFixed(2) + " seconds" : "..."}</p>
          </div>
          <div>
            <p>NEO/GAS Ratio</p>
            <p>{loaded === true ? info.neo_gas_ratio : "..."}</p>
          </div>
          <div>
            <p>NEO Price</p>
            <p onMouseEnter={() => setHovering("neo_price")} onMouseLeave={() => setHovering("")}>
              {loaded ? (hovering === "neo_price" ? convertCurrency(info.neo_price) : info.neo_price + " USD") : "..."}
            </p>
          </div>
          <div>
            <p>GAS Price</p>
            <p onMouseEnter={() => setHovering("gas_price")} onMouseLeave={() => setHovering("")}>
              {loaded ? (hovering === "gas_price" ? convertCurrency(info.gas_price) : info.gas_price + " USD"): "..."}
            </p>
          </div>
        </div>
        <div className="neo-holdings">
          <p>How much NEO are you hodling?</p>
          <InputNumber min={1} value={neoHoldings} onChange={value => setNeoHoldings(value || 1)}/>
        </div>
        <div className="table">
          <Table columns={columns} dataSource={info.table_data} pagination={false} scroll={{x: 750}} loading={!loaded}/>
        </div>
        <div className="disclaimer">
          <p>DISCLAIMER: The information provided are estimates only. We do not guarantee the accuracy of any information provided on this website.</p>
          <p>GAS generation per year: {info.gas_per_year || 10512000} (5 GAS generated per block)</p>
          <p>Base GAS per NEO per year: {info.base_gas_per_year || 0.10512} (10% split between all NEO holders)</p>
          <p>GAS per voted NEO per year: 40% * GAS generated per year / (7 or 14) / number of votes</p>
          <p>APR: (Base GAS Per Neo + GAS per voted NEO) * GAS price / NEO price</p>
        </div>
      </div>
  );
};

export default Home;